import {
  requestCMSHomepageSSR,
  UseCMSHomepage,
  useCMSHomepage,
  useMobile,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import { VisibleContainer } from "@with-nx/simple-ui/molecules";
import {
  CatalogLayout,
  PromotionSection,
  TestimonialQuotes,
} from "@with-nx/simple-ui/organisms";
import {
  FormsCTA,
  HomeCommercialTemplate,
  HomeCommunityTemplate,
  HomeCustomerService,
  HomeInstagramTemplate,
  HomePartnersTemplate,
  HomeSlidersTemplate,
  HomeStoriesTemplate,
  HomeTestimonialsTemplate,
  LatestDigitalDrops,
  MobileHomePage,
} from "@with-nx/simple-ui/templates";
import { LatestDigitalDropsStaticData } from "@with-nx/static";
import { Products } from "libs/hooks-n-helpers/src/types";
import { Box } from "simple-effing-primitive-layout";

export default function Page({
  resolvedUrl,
  homepageDataPreload,
  latestDigitalDrops,
}: {
  resolvedUrl: string;
  homepageDataPreload: UseCMSHomepage;
  latestDigitalDrops: Products;
}) {
  const mobile = useMobile();
  const region = useServerRegion();
  const _homepage = useCMSHomepage({
    region: region?.id,
  });

  const homepage = _homepage.loading ? homepageDataPreload : _homepage.data;

  if (mobile) {
    return (
      <MobileHomePage
        homepage={homepage}
        latestDigitalDrops={latestDigitalDrops}
      />
    );
  }

  return (
    <CatalogLayout
      transparentFixedNavBar={true}
      resolvedUrl={resolvedUrl}
      description="Elevate your production with Broadway Media, committed to providing affordable theatrical resources. Explore Scenic Projections, projectors for stage, wireless body mics, & more."
    >
      <HomeSlidersTemplate homepage={homepage} />
      <HomeStoriesTemplate homepage={homepage} />

      <VisibleContainer>
        <>
          <LatestDigitalDrops drops={latestDigitalDrops} />
          {homepage?.testimonials?.length ? (
            <Box parse="mh:450">
              <TestimonialQuotes
                testimonials={homepage?.testimonials?.slice(0, 5) || []}
              />
            </Box>
          ) : undefined}

          <HomeCommunityTemplate homepage={homepage} />
          <HomeCustomerService />
          <PromotionSection page="Homepage" />
          <FormsCTA />
          <HomeTestimonialsTemplate homepage={homepage} />
          <HomeCommercialTemplate homepage={homepage} />
          <HomeInstagramTemplate homepage={homepage} />
          <HomePartnersTemplate homepage={homepage} />
        </>
      </VisibleContainer>
    </CatalogLayout>
  );
}

export const getServerSideProps = async ({ resolvedUrl }) => {
  const homepageDataPreload = await requestCMSHomepageSSR(1);
  const latestDigitalDrops = LatestDigitalDropsStaticData.isEmpty()
    ? []
    : LatestDigitalDropsStaticData.getAll();

  return {
    props: {
      resolvedUrl,
      homepageDataPreload,
      latestDigitalDrops,
    },
  };
};
